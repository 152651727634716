<template>
    <div>
        <nav-bar title="提现详情" url="/sale_withdraw"></nav-bar>
        <div class="top">
            <div class="title">
                <div class="title-text">
                    基本信息
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-cell title="收款用户" :value="info.user.sale_cert_name"></van-cell>
                <van-cell title="提现单号" :value="info.with_partner_trade_no" v-if="info.with_partner_trade_no"></van-cell>
                <van-cell title="提现金额" :value="'￥'+info.with_total_money"></van-cell>
                <van-cell title="到账金额" :value="'￥'+info.with_money"></van-cell>
                <van-cell title="个税金额" :value="'￥'+info.with_service_money"></van-cell>
                <van-cell title="提现时间" :value="info.with_submit_time"></van-cell>
                <van-cell title="状态" >
                    <template slot="default">
                        <van-tag plain type="primary" v-if="info.with_state == 0">待审核</van-tag>
                        <van-tag plain type="success" v-if="info.with_state == 1">审核通过</van-tag>
                        <van-tag plain type="danger" v-if="info.with_state == 2">审核不过</van-tag>
                        <van-tag plain type="success" v-if="info.with_state == 3">提现成功</van-tag>
                        <van-tag plain type="danger" v-if="info.with_state == 4">提现失败</van-tag>
                        <van-tag plain type="warning" v-if="info.with_state == 5">提现中</van-tag>
                    </template>
                </van-cell>
                <van-cell title="审核内容" :value="info.with_examine_content"></van-cell>
                <van-cell title="审核时间" :value="info.with_examine_time" v-if="info.with_examine_time"></van-cell>
                <van-cell title="完成时间" :value="info.with_finish_time" v-if="info.with_finish_time"></van-cell>
            </van-cell-group>
        </div>

    </div>
</template>

<script>
    import {
        sale_withdraw_detail,
    } from '@/api/sale.js';
    export default {
        name: 'sale_withdraw_detail',
        data() {
            return {
                info: {},
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {
            get_data() {
                sale_withdraw_detail({
                    id: this.$route.query.id,
                    loading:true,
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                    }
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }
</style>